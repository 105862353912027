import React from 'react';
import { graphql } from 'gatsby';
import { Col, Row, Container } from 'reactstrap';
import Layout from '../components/layout';
import SEO from '../components/SEO';
import PageHeader from '../components/Sections/PageHeader';
import ContactForm from '../components/Sections/ContactForm';
import GoogleMap from '../components/Sections/GoogleMap';

const ContattiPage = ({ data }) => {
  const company = data.datoCmsCompany;
  return (
    <Layout>
      <SEO title="Contatti" />
      <PageHeader title="Contatti" />
      <div id="page" className="section">
        <Container>
          <Row>
            <Col sm="4">
              <h5>
                {company.name}
              </h5>
              <p>
                {' '}
                {company.address}
                {' '}
                {company.zip}
                {' '}
                {company.city}
              </p>
              <p>
                {' '}
                {company.phone}
              </p>
              <p>
                <a href={`mailto:${company.email}`}>{company.email}</a>
              </p>
              <p>
P.Iva:
                {' '}
                {company.vatNumber}
              </p>
            </Col>
            <Col sm="8">
                <div className="d-flex flex-column">
                <ContactForm />
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col sm="12" className="mt-2 mt-sm-3">
              <GoogleMap />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default ContattiPage;

export const pageQuery = graphql`
  query contattiQuery {
    datoCmsCompany {
      email
      city
      address
      name
      phone
      zip
      vatNumber
    }
  }
`;
